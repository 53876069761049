<template>
  <div>
    <CRow>
      <CCol sm="8">
        <CCard>
          <form id="CreateSupplier" @submit="CreateSupplier">
            <CCardHeader>
              <CCol col="6" style="font-size: 25px">إضافة مورد جديد</CCol>
            </CCardHeader>
            <CCardBody>
              <div class="form-group">
                    <label style="padding: 5px"
                      >الاسم <span class="star">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="Supplier.name"
                      aria-describedby="validateName"
                      required
                    />
                    <div v-if(errors.name) id="validateName" class="invalid-feedback">
                      {{errors.name}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label style="padding: 5px"
                      >البريد الالكتروني <span class="star">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      v-model="Supplier.email"
                      aria-describedby="validateEmail"
                      required
                    />
                    <div v-if(errors.email) id="validateEmail" class="invalid-feedback">
                      {{errors.email}}
                    </div>
                  </div>
                  <CRow>
                    <CCol sm="12">
                      <div class="form-group">
                        <label style="padding: 5px"
                          >الهاتف <span class="star">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="Supplier.mobile"
                          placeholder=" 00974XXXXXXXX ادخل رقم الهاتف"
                          aria-describedby="validateMobile"
                          required
                        />
                        <div v-if(errors.mobile) id="validateMobile" class="invalid-feedback">
                          {{errors.mobile}}
                        </div>
                      </div>
                    </CCol> </CRow
                  ><br />
                <div class="form-group">
                  <label style="padding: 5px">
                    الوصف 
                    </label>
                  <textarea
                    type="text"
                    class="form-control"
                    v-model="Supplier.description"
                  ></textarea>
                </div>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary"
                ><CIcon name="cil-check-circle" /> حفظ</CButton
              >
              &emsp;
              <CButton type="reset" size="sm" color="danger"
                ><CIcon name="cil-ban" /> مسح
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "CreateSupplier",
  data: function () {
    return {
      Supplier: {},
      errors: []
    };
  },
  methods: {
    addMobile: function (e) {
      console.log(e);
      this.mobile.push({ value: "" });
    },
    addEmail: function () {
      this.email.push({ value: "" });
    },
    CreateSupplier: function (e) {
      e.preventDefault();
      let currentObj = this;
      let formData = new FormData();
      formData.append("name", this.Supplier.name);
      formData.append("mobile", this.Supplier.mobile);
      formData.append("email", this.Supplier.email);
      if (this.Supplier.description) formData.append("description", this.Supplier.description);
      $('[class~="images[]"]', this.el).each(function (i) {
        if (i > this.maxImages - 1) {
          return; // Max images reached.
        }

        formData.append("extra_image[" + i + "]", this.files[0]);
      });

      this.$http
        .post(`${process.env.VUE_APP_URL}suppliers`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          currentObj.output = response.data;
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
            currentObj.$router.push({ path: "/suppliers" });
          } else {
            currentObj.errors = response.data.data;
          }
        })
        .catch(function (error) {
          currentObj.output = error;
        });
    },
  },
};
</script>

<style>
.invalid-feedback {
  display: inherit;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>